export const poolData = [
    {
        title: '$BONE / DogSwap',
        subTitle: 'Stake your $BONE / DogSwap LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 6,
        poolType: 'Weekly',
        active: 'Yes',
        fee: '0.5',
        lpTokenAddress: '0x07Da7DA47b3C71a023d194ff623ab3a737c46393',
        imageTokenA: 'https://dogswap.xyz/images/coins/bone.png',
        imageTokenB: 'https://dogswap.xyz/images/coins/dogswap.png',
    },
    {
        title: '$BONE / 1000x',
        subTitle: 'Stake your $BONE / 1000x LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 8,
        poolType: 'Weekly',
        active: 'Yes',
        fee: '1',
        lpTokenAddress: '0x9763E377ce4E6767F7760D9E1FC7E3c2afBc9Cfb',
        imageTokenA: 'https://dogswap.xyz/images/coins/1000x.png',
        imageTokenB: 'https://dogswap.xyz/images/coins/bone.png',
    },
    {
        title: '$BONE / XatteR',
        subTitle: 'Stake your $BONE / XatteR LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 13,
        poolType: 'Weekly',
        active: 'Yes',
        fee: '1.5',
        lpTokenAddress: '0x32D8Da81B7b4a562b0852ed6823BE8b2CCFa6495',
        imageTokenA: 'https://dogswap.xyz/images/coins/bone.png',
        imageTokenB: 'https://dogswap.xyz/images/coins/xatter.png',
    },
    {
        title: '$BONE / Dance and Music',
        subTitle: 'Stake your $BONE / Dance and Music LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 11,
        poolType: 'Weekly',
        active: 'Yes',
        fee: '1.5',
        lpTokenAddress: '0xDaEEc4298a0ED4796E271188D2D7Bbcf53733Fb1',
        imageTokenA: 'https://dogswap.xyz/images/coins/bone.png',
        imageTokenB: 'https://dogswap.xyz/images/coins/DAM.png',
    },
    {
        title: '$BONE / $RANGER',
        subTitle: 'Stake your $BONE / $RANGER LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 21,
        poolType: 'Weekly',
        active: 'Yes',
        fee: '2.5',
        lpTokenAddress: '0xC5a3D921a8c3FA452c278D5278EA1aeb28C0Ecb2',
        imageTokenA: 'https://dogswap.xyz/images/coins/bone.png',
        imageTokenB: 'https://dogswap.xyz/images/coins/ranger.png',
    },
    {
        title: '$BONE / Zarali',
        subTitle: 'Stake your $BONE / Zarali LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 12,
        poolType: 'Weekly',
        active: 'Yes',
        fee: '10',
        lpTokenAddress: '0xE4020B6d3073B79027e2350705f9230903130791',
        imageTokenA: 'https://dogswap.xyz/images/coins/bone.png',
        imageTokenB: 'https://dogswap.xyz/images/coins/zarali.png',
    },
    {
        title: '$BONE / BANG',
        subTitle: 'Stake your $BONE / BANG LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 14,
        poolType: 'Weekly',
        active: 'Yes',
        fee: '10',
        lpTokenAddress: '0x84D0Ee4262cfD4ea222554D4FAE1A5Df7c38D9Be',
        imageTokenA: 'https://dogswap.xyz/images/coins/bone.png',
        imageTokenB: 'https://dogswap.xyz/images/coins/bang.png',
    },
    {
        title: '$BONE / DGONE',
        subTitle: 'Stake your $BONE / DGONE LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 15,
        poolType: 'Weekly',
        active: 'Yes',
        fee: '1.5',
        lpTokenAddress: '0x5D8274da127f7D94b46D123090707BBBCd4Ed119',
        imageTokenA: 'https://dogswap.xyz/images/coins/bone.png',
        imageTokenB: 'https://dogswap.xyz/images/coins/dgone.png',
    },
    {
        title: '$BONE / WhaleBux',
        subTitle: 'Stake your $BONE / WBUX LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 22,
        poolType: 'Weekly',
        active: 'Yes',
        fee: '5.0',
        lpTokenAddress: '0xD288C9aC27b608dB905B98AB0fDfd0Ea68059ecc',
        imageTokenA: 'https://dogswap.xyz/images/coins/bone.png',
        imageTokenB: 'https://dogswap.xyz/images/coins/WBUX.png',
    },
    {
        title: '$BONE / bobdubloon',
        subTitle: 'Stake your $BONE / bobdubbloon LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 16,
        poolType: 'Weekly',
        active: 'Yes',
        fee: '1.5',
        lpTokenAddress: '0x1607D3CaB18F24e3De082Fd2F297b0d3B0fc0112',
        imageTokenA: 'https://dogswap.xyz/images/coins/bone.png',
        imageTokenB: 'https://dogswap.xyz/images/coins/bobdub.jpeg',
    },
    {
        title: '$BONE / CLICK',
        subTitle: 'Stake your $BONE / CLICK LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 17,
        poolType: 'Weekly',
        active: 'Yes',
        fee: '10',
        lpTokenAddress: '0xf099c8D1cd95608F5D5e70da8581c4981d4f3a3f',
        imageTokenA: 'https://dogswap.xyz/images/coins/bone.png',
        imageTokenB: 'https://dogswap.xyz/images/coins/click.png',
    },
    {
        title: '$BONE / DClub',
        subTitle: 'Stake your $BONE / DClub LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 18,
        poolType: 'Weekly',
        active: 'Yes',
        fee: '10',
        lpTokenAddress: '0x66b700c1039182C6fD653b429A14bf4BC2d98a4A',
        imageTokenA: 'https://dogswap.xyz/images/coins/bone.png',
        imageTokenB: 'https://dogswap.xyz/images/coins/dclub.png',
    },
    {
        title: '$BONE / Shells',
        subTitle: 'Stake your $BONE / Shells LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 19,
        poolType: 'Weekly',
        active: 'Yes',
        fee: '10',
        lpTokenAddress: '0x662ca8cAceBfd41442c536D0153Ed181bfD34c60',
        imageTokenA: 'https://dogswap.xyz/images/coins/bone.png',
        imageTokenB: 'https://dogswap.xyz/images/coins/shells.jpeg',
    },
    {
        title: '$BONE / DWMW',
        subTitle: 'Stake your $BONE / DWMW LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 20,
        poolType: 'Weekly',
        active: 'Yes',
        fee: '10',
        lpTokenAddress: '0xee1780dc28D0948fe46FC10fcED1B44F5d1a5971',
        imageTokenA: 'https://dogswap.xyz/images/coins/bone.png',
        imageTokenB: 'https://dogswap.xyz/images/coins/dwmw.png',
    },
  ];