const newsItems = [
    "Visit our featured pool: https://beta.dogswap.xyz/featured",
    "The previous release is still available on https://old.dogswap.xyz",
    "Does anyone know the location of John Connor?",
    "The Bone Pools are active when payment is processed on MintMe!",
    "Congratz DreadKnight on receiving a free featured spot!",
    "Visit XatteR here: https://ancientbeast.com/",
    "Spotted an error, spelling mistake or bug? Shoot me a message!",
    "DogSwap is looking for active community members!",
  ];
  
  export default newsItems;
